import { createTheme, responsiveFontSizes } from '@mui/material';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#171717',
      light: '#343a40',
    },
    secondary: {
      main: '#8D8D8D',
      light: '#e9ecef',
      contrastText: '#008080',
      100: '#d32f2f',
      A100: '#EBEBE4',
    },
    grey: {
      100: '#F2F2F2',
      200: '#D9D9D9',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#ccc"
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#008080"
          }
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: "#008080",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#008080"
          }
        }
      }
    },
  },
});

export const theme = responsiveFontSizes(muiTheme);
